<template>
  <div class="auth-wrapper auth-v2">
    <b-link class="brand-logo" :to="{ name: 'login' }">
      <artos-logo />

      <h2 class="brand-text text-primary ml-1">
        ARTOS
      </h2>
    </b-link>
    <b-row class="auth-inner m-0 justify-content-center" align-h="center" align-v="center">
        <b-col
          cols="10"
          md="4"
          class="d-flex justify-content-center justify-content-md-end"
        >
          <b-img
            fluid
            :src="sideImg"
            alt="Illustration of Signup"
          />
        </b-col>
        <b-col
          cols="10"
          md="4"
          class="d-flex flex-column justify-content-start"
        >
          <b-card-title
            v-if="!invalid"
            title-tag="h2"
            class="d-block font-weight-bold mb-1 text-center"
          >
            Successfully joined the company!
            <br>
            <small>If you are a new user. We have sent you a temporary password to login into Artos application.</small>
          </b-card-title>

          <b-card-title
            v-else
            title-tag="h2"
            class="d-block font-weight-bold mb-1 text-center"
          >
            {{ this.$t('pages.signup.activationFailed') }}
          </b-card-title>

          <p class="text-center mt-1 mb-0" v-if="!invalid">
            <b-button
              :to="{name:'login'}"
              variant="primary">
              <span>&nbsp;{{ this.$t('pages.signup.actions.signIn') }}</span>
            </b-button>
          </p>
        </b-col>
      <!-- </b-row> -->
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import ArtosLogo from '@/components/logo/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BImg,
  BCardTitle
} from 'bootstrap-vue'
import router from '@/router'

import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'

export default {
  components: {
    ArtosLogo,
    BRow,
    BCol,
    BLink,
    BButton,
    BImg,
    BCardTitle
  },
  setup () {
    const { $post } = useHttp()

    const invalid = ref(false)
    const sideImg = ref(require('@/assets/images/pages/signup/verified-email.svg'))

    onMounted(() => {
      $post({
        url: 'company/user-invitation/activation',
        data: router.currentRoute.params
      })
        .catch(() => {
          invalid.value = true
        })
    })

    return {
      invalid,
      sideImg
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
